





















































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { CropName } from "@/store/models/crop";
import { Sale } from "@/store/models/DemandAndSupply";
import {
  CropStore,
  DemandSupplyStore,
  CropVarietyStore,
  UserStore,
  JWTStore,
  MetaStore
} from "@/store/modules";
import DynamicField from "@/views/Admin/DynamicField.vue";
import { MetaInfo } from "vue-meta";
import { eventHub } from "@/utils/eventHub";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component({
  components: {
    DynamicField
  },
  metaInfo(): MetaInfo {
    return {
      title: `${this.$tc("addSale", 1)}`,
      meta: [
        { name: "keyword", content: "Sqcc sale list" },
        {
          name: "description",
          content: "List of Supplies of seeds",
          vmid: "saleList"
        }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  Id: string = "" as any;
  pageNum: number = 1;
  saleCrop: CropName = {} as CropName;
  sale: Sale = {} as Sale;
  language: string = "";
  varietyName: string = "";
  seedType: number = 1;
  qty: string = "";
  clearButton: boolean = false;
  loading: boolean = false;
  adding: boolean = false;
  multipleSelection: any[] = [];

  proofOfSale: File[] = [] as File[];

  get dataList() {
    return DemandSupplyStore.Sales;
  }

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  indexMethod(index: number) {
    return this.nepaliUnicodeNumber(index + 1);
  }

  thousandSeprator(amount: number | string) {
    if (amount == null) {
      return null;
    }
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  handleSelectionChange(val: any) {
    this.multipleSelection = val;
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }

  private get user() {
    return UserStore.User;
  }

  private get crops() {
    return CropStore.DSADCropList;
  }

  private get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  private get varieties() {
    let varieties = CropVarietyStore.CropVarieties;
    if (!this.sale.dsadgroupid) {
      return [];
    }
    return varieties;
  }

  loadCropVariety(event: any) {
    this.sale.cropvarietyid = null as any;
    let cropGroupId = this.crops.filter(x => x.groupid == event)[0].groupid;
    if (cropGroupId != undefined) {
      CropVarietyStore.getAllowedCropVarietiesByCropId({
        id: cropGroupId,
        lang: this.$i18n.locale
      });
    }
  }

  roleChecker(roles: String[]) {
    for (let role of roles) {
      if (this.user.role === role) {
        return true;
      }
    }
    return false;
  }

  seedSetter() {
    if (this.roleChecker(["source_seed_producer"])) {
      let seedTypeId = this.seedTypes.find(x => x.name.toLowerCase() === "source");
      if (seedTypeId) {
        this.sale.seedtypeid = seedTypeId.id;
      }
    } else if (
      this.roleChecker([
        "improved_seed_producer",
        "improved_seed_distributor",
        "improved_seed_consumer"
      ])
    ) {
      let seedTypeId = this.seedTypes.find(x => x.name.toLowerCase() === "improved seed");
      if (seedTypeId) {
        this.sale.seedtypeid = seedTypeId.id;
      }
    }
  }

  handleAfterUploadSaleProof(file: any) {
    this.proofOfSale = [];
    this.proofOfSale.push(file.raw);
  }

  async add() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      this.seedSetter();
      let batchid = this.crops.filter(x => x.groupid == this.sale.dsadgroupid)[0].batchid;
      if (batchid) {
        this.sale.batchid = batchid;
      }
      let submitSale = new FormData();
      for (let key in this.sale as any) {
        submitSale.append(key, (this.sale as any)[key]);
      }
      // submitSale.append("lang", this.$i18n.locale);

      for (let file of this.proofOfSale) {
        submitSale.append("proof", file);
      }

      this.adding = true;
      let error = false;
      let sale = await DemandSupplyStore.postSale(submitSale).catch(res => {
        error = true;
      });
      this.adding = false;
      if (!error) {
        sale = sale as Sale;
        if (!this.clearButton) {
          this.dataList.push(sale);
          this.$notify.success(`${this.$tc("sale")} ${this.$tc("added")}`);
        } else {
          this.$notify.success(`${this.$tc("sale")} ${this.$tc("edited")}`);
        }
        (this.$refs.dataTable as any).toggleAllSelection();
        this.clear();
      }
    }
  }

  async confirm() {
    let selection: any[] = [];
    for (let data of this.multipleSelection) {
      selection.push(data.id);
    }
    this.$confirm(this.$t("confirmSaleMessage").toString(), this.$t("warning").toString(), {
      confirmButtonText: this.$t("submit").toString(),
      confirmButtonClass: "el-button--primary",
      cancelButtonText: this.$t("cancel").toString(),
      showClose: false,
      type: "warning"
    })

      .then(async () => {
        await DemandSupplyStore.confirmSales(selection);
        this.$notify.success(`${this.$tc("sale", 2)} ${this.$tc("submitted")}`);
        for (let data of this.multipleSelection) {
          this.dataList.splice(this.dataList.indexOf(data), 1);
        }
      })

      .catch(() => {
        this.$message({
          type: "info",
          message: this.$t("cancelled").toString()
        });
      });
  }

  clear() {
    this.sale = {} as Sale;
    this.clearButton = false;
  }

  editForm(id: number) {
    this.sale = Object.assign({}, this.dataList.filter(x => x.id == id)[0]);
    this.clearButton = true;
  }

  onDeleteClick(index: number, id: number) {
    let sale = this.dataList.filter(x => x.id == id)[0];
    let confirmMessage = `This will permanently delete the sale of crop ${sale.croptypename} of variety ${sale.cropvarietyname} and all related data. Do you want to continue?`;
    if (this.$i18n.locale == "ne") {
      confirmMessage = `यसले स्थायी रूपमा ${sale.croptypename} बालीको ${sale.cropvarietynepname} प्रजातिको बिक्री र सबै सम्बन्धित डाटलाई मेटाउनेछ। के तपाईं मेटाउन चाहानुहुन्छ?`;
    }
    this.$confirm(confirmMessage, this.$t("warning").toString(), {
      confirmButtonText: this.$t("delete").toString(),
      confirmButtonClass: "el-button--danger",
      cancelButtonText: this.$t("cancel").toString(),
      showClose: false,
      type: "warning"
    })
      .then(async () => {
        await DemandSupplyStore.deleteSale(sale.id);
        this.dataList.splice(index, 1);
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: this.$t("cancelled").toString()
        });
      });
  }

  isDistributor() {
    let userRole = this.user.role;
    let roles = ["improved_seed_distributor"];
    for (let role of roles) {
      if (userRole === role) {
        return true;
      }
    }
    return false;
  }

  private async created() {
    if (JWTStore.loggedIn) {
      await UserStore.getUser();
    }
    if (JWTStore.loggedIn && this.isDistributor()) {
      this.language = this.$i18n.locale;
      if (this.$route.query.page) {
        this.pageNum = Number(this.$route.query.page);
      }
      CropStore.getDSADCropName({ phase: "sale", lang: this.language });
      DemandSupplyStore.getAllSeedType();
      this.loading = true;
      await DemandSupplyStore.getAllSales(this.language);
      this.loading = false;

      (this.$refs.dataTable as any).toggleAllSelection();
    } else {
      eventHub.$emit("loginClick", "companyUser", "You need to login company user to continue");
    }
  }

  handlePageChange(val: number) {
    this.pageNum = val;
    CropVarietyStore.getPaginatedCropValue({
      search: this.searchInput,
      page: val,
      language: this.$i18n.locale
    });

    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: { page: String(val) }
        })
        .catch(error => {});
    }
  }
}
