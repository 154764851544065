





























































































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { Demand } from "@/store/models/DemandAndSupply";
import { eventHub } from "@/utils/eventHub";
import { MetaStore } from "@/store/modules";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

import {
  CropVarietyStore,
  CropStore,
  DemandSupplyStore,
  JWTStore,
  UserStore
} from "@/store/modules";
import { MetaInfo } from "vue-meta";

@Component({
  components: {},
  metaInfo(): MetaInfo {
    return {
      title: `${this.$tc("addDemand", 1)}`,
      meta: [
        { name: "keyword", content: "Sqcc demand list" },
        {
          name: "description",
          content: "List of Demands of seeds",
          vmid: "demandList"
        }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  cropId: number = 0;
  cropvariety = {} as CropVariety;
  demand: Demand = {} as Demand;
  pageNum: number = 1;
  language: string = "";
  selectedIndex: number = -1;
  clearButton: boolean = false;
  loading: boolean = false;
  adding: boolean = false;
  multipleSelection: any[] = [];
  role: string = "";
  crop: number = 0;
  disableConfirm: Boolean = false;

  selectedSupplier: number | null = null;

  get dataList() {
    return DemandSupplyStore.Demands;
  }

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  get subsidySchedule() {
    return DemandSupplyStore.SubsidySchedule;
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  indexMethod(index: number) {
    return this.nepaliUnicodeNumber(index + 1);
  }

  // get suppliers() {
  //   return DemandSupplyStore.Suppliers;
  // }

  get cropValuesFromStore() {
    return CropVarietyStore.pageValues;
  }

  async confirm() {
    let selection: any[] = [];
    for (let data of this.dataList) {
      selection.push(data.id);
    }
    this.$confirm(
      this.$t("confirmDemandMessage")
        .toString()
        .toString(),
      {
        confirmButtonText: this.$t("submit").toString(),
        confirmButtonClass: "el-button--primary",
        cancelButtonText: this.$t("cancel").toString(),
        showClose: false,
        type: "warning"
      }
    )

      .then(async () => {
        await DemandSupplyStore.confirmDemands(selection);
        this.$notify.success(`${this.$tc("demand", 2)} ${this.$tc("submitted")}`);
        this.$router.push({
          name: "CompanyMenu"
        });
        // for (let data of this.multipleSelection) {
        //   this.dataList.splice(this.dataList.indexOf(data), 1);
        // }
      })

      .catch(() => {
        this.$message({
          type: "info",
          message: this.$t("cancelled").toString()
        });
      });
  }

  thousandSeprator(amount: any) {
    if (amount == null) {
      return null;
    }
    if (
      amount !== undefined ||
      amount !== 0 ||
      amount !== "0" ||
      amount !== null ||
      amount !== NaN
    ) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  handleSelectionChange(val: any) {
    this.multipleSelection = val;
    for (let row in val) {
      if (!val[row].canbeconfirmed) {
        val.splice(row, 1);
      }
    }
    this.multipleSelection = val;
    return this.multipleSelection;
  }

  canBeSelected(row: any, index: any) {
    return row.canbeconfirmed;
  }

  getSupplierLabel(supplier: any) {
    if (this.$i18n.locale == "ne") {
      return supplier.nepname;
    }
    return supplier.name;
  }

  getSummaries(param: any) {
    const { columns, data } = param;
    const sums: any[] = [];
    columns.forEach((column: any, index: any) => {
      if (index === 0) {
        sums[index] = this.$t("total");
        return;
      }
      const values = data.map((item: any) => Number(item[column.property]));
      if (!values.every((value: any) => isNaN(value))) {
        sums[index] = values.reduce((prev: any, curr: any) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
        sums[index] = this.nepaliUnicodeNumber(this.thousandSeprator(sums[index]));
      } else {
        sums[index] = "";
      }
    });

    return sums;
  }

  async searchText() {
    this.loading = true;
    await CropVarietyStore.getPaginatedCropValue({
      search: this.searchInput,
      page: 1
    });
    this.loading = false;
    // CropVarietyStore.getSearchedCropValue(this.searchInput);
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }

  private get user() {
    return UserStore.User;
  }

  get crops() {
    const subsidy = DemandSupplyStore.SubsidyCrops;
    const crops = subsidy.map(x => {
      return { id: x.crop_type, name: x.crop_name };
    });
    return crops;
  }

  get cropName() {
    const crop = this.crops.find(x => x.id == this.crop);
    if (crop) {
      return crop.name;
    }
    return "";
  }

  get addDemandText() {
    let text = this.$tc("addDemand");
    if (this.$i18n.locale == "ne") {
      text = `${this.cropName}${this.$t("'s")} ${text}`;
    } else {
      text = `${text} of ${this.cropName}`;
    }
    return text;
  }

  get varieties() {
    const subsidy = DemandSupplyStore.Subsidy;
    let varieties = subsidy.map(x => {
      return { id: x.crop_variety, name: x.variety_name, crop_type: x.crop_type };
    });
    if (this.cropId == 0) {
      return [];
    } else {
      varieties = varieties.filter(x => x.crop_type == this.cropId);
    }
    return varieties;
  }

  get suppliers() {
    return DemandSupplyStore.Suppliers;
  }

  private get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  seedSetter() {
    if (this.user.role === "improved_seed_producer") {
      let seedTypeId = this.seedTypes.find(x => x.name.toLowerCase() === "source");
      if (seedTypeId) {
        this.demand.seedtypeid = seedTypeId.id;
      }
    } else if (
      this.user.role === "improved_seed_distributor" ||
      this.user.role === "improved_seed_consumer"
    ) {
      let seedTypeId = this.seedTypes.find(x => x.name.toLowerCase() === "improved seed");
      if (seedTypeId) {
        this.demand.seedtypeid = seedTypeId.id;
      }
    }
  }

  get currentSubsidyBatch() {
    return DemandSupplyStore.CurrentSubsidyBatch;
  }

  async add() {
    let isValid = await this.$validator.validateAll();
    this.seedSetter();
    if (
      (!this.demand.id || this.demand.id == 0) &&
      this.dataList.filter(
        x => x.cropvarietyid == this.demand.cropvarietyid && x.seedtypeid == this.demand.seedtypeid
      ).length > 0
    ) {
      isValid = false;
      this.$notify.error("Demand already exists");
    }
    // if (this.role == "improved_seed_consumer") {
    //   this.demand.seedtypeid = 4;
    // }
    // if (this.selectedSupplier) {
    //   let suppliers = this.suppliers.filter(x => x.id == this.selectedSupplier);
    //   if (this.suppliers) {
    //     this.demand.preferredsuppliers = [
    //       {
    //         supplieruserid: this.suppliers[0].id
    //       }
    //     ];
    //   }
    // }
    this.demand.preferredsuppliers = [];
    if (isValid && this.currentSubsidyBatch && this.currentSubsidyBatch.id) {
      this.seedSetter();

      // let batchid = this.crops.filter(x => x.groupid == this.demand.dsadgroupid)[0].batchid;
      // if (batchid) {
      //   this.demand.batchid = batchid;
      // }

      this.adding = true;
      // let submitDemand = new FormData();
      // for (let key in this.demand as any) {
      //   console.log(key, (this.demand as any)[key]);
      //   if ((this.demand as any)[key]) {
      //     submitDemand.append(key, (this.demand as any)[key]);
      //   }
      // }

      // submitDemand.append("subsidy_batch", String(this.currentSubsidyBatch.id));
      // if (this.selectedSupplier) {
      //   submitDemand.append("supplier_id", String(this.selectedSupplier));
      //   this.demand.supplier_id = this.selectedSupplier;
      // }
      this.demand.subsidy_batch = this.currentSubsidyBatch.id;
      let error = false;
      let demand = await DemandSupplyStore.postDemand(this.demand).catch(res => {
        error = true;
      });
      this.adding = false;
      if (!error) {
        demand = demand as Demand;
        if (!this.clearButton) {
          this.dataList.push(demand);
          this.$notify.success(`${this.$tc("demand")} ${this.$tc("added")}`);
        } else {
          this.dataList.splice(this.selectedIndex, 1, demand);
          this.$notify.success(`${this.$tc("demand")} ${this.$tc("edited")}`);
        }
        (this.$refs.dataTable as any).toggleAllSelection();
        this.clear();
      }
    } else if (!this.currentSubsidyBatch || !this.currentSubsidyBatch.id) {
      this.$notify.error("Couldn't get current Subsidy Batch!! Please refresh the page.");
    }

    DemandSupplyStore.getAllSupplies({ lang: this.language, crop: this.crop });

    if (this.dataList.length == 0) {
      this.disableConfirm = true;
    } else {
      this.disableConfirm = false;
    }
  }

  clear() {
    this.demand = {} as Demand;
    this.selectedSupplier = null;
    this.selectedIndex = -1;
    this.clearButton = false;
  }

  editForm(id: number) {
    this.selectedIndex = this.dataList.findIndex(x => x.id == id);
    this.demand = Object.assign({}, this.dataList.filter(x => x.id == id)[0]);
    this.clearButton = true;
    this.loadSuppliers(null);
  }

  onDeleteClick(index: number, id: number) {
    let cropVariety = this.dataList.filter(x => x.id == id)[0];
    if (cropVariety != undefined) {
      let confirmMessage = `This will permanently delete the demand of crop ${cropVariety.croptypename} of variety ${cropVariety.cropvarietyname} and all related data. Do you want to continue?`;
      if (this.$i18n.locale == "ne") {
        confirmMessage = `यसले स्थायी रूपमा ${cropVariety.croptypename} बालीको ${cropVariety.cropvarietynepname} प्रजातिको माग र सबै सम्बन्धित डाटलाई मेटाउनेछ। के तपाईं मेटाउन चाहानुहुन्छ?`;
      }
      this.$confirm(confirmMessage, this.$t("warning").toString(), {
        confirmButtonText: this.$t("delete").toString(),
        confirmButtonClass: "el-button--danger",
        cancelButtonText: this.$t("cancel").toString(),
        showClose: false,
        type: "warning"
      })
        .then(async () => {
          await DemandSupplyStore.deleteDemand(id);
          this.dataList.splice(index, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("cancelled").toString()
          });
        });
    }
  }

  loadCropVariety(event: any) {
    this.seedSetter();
    // this.demand.cropvarietyid = null as any;
    // let cropGroupId = this.crops.filter(x => x.groupid == event)[0].groupid;
    // if (cropGroupId != undefined) {
    //   CropVarietyStore.getAllowedCropVarietiesByCropId({
    //     id: cropGroupId,
    //     lang: this.$i18n.locale
    //   });
    // }
  }

  loadSuppliers(event: any) {
    this.seedSetter();
    this.selectedSupplier = null;
    if (
      this.demand.cropvarietyid != undefined &&
      this.demand.cropvarietyid != undefined &&
      this.demand.seedtypeid &&
      this.demand.seedtypeid &&
      this.demand.cropvarietyid != 0 &&
      this.demand.seedtypeid != 0
    ) {
      DemandSupplyStore.getSuppliers({
        fiscalYearId: this.currentSubsidyBatch.fiscal_year,
        cropVarietyId: this.demand.cropvarietyid,
        seedTypeId: this.demand.seedtypeid
      });
    }
  }

  isConsumer() {
    let userRole = this.user.role;
    let roles = [
      "supplier",
      "consumer",
      "source_seed_producer",
      "improved_seed_producer",
      "improved_seed_distributor",
      "improved_seed_consumer"
    ];
    for (let role of roles) {
      if (userRole === role) {
        return true;
      }
    }
    return false;
  }

  // next(){
  //   this.$router.push({name: "AddDemandDocument", query:{ crop: String(this.cropId) }})
  // }

  async created() {
    this.seedSetter();
    this.role = JWTStore.role;
    this.cropId = Number(this.$route.query.crop);
    if (JWTStore.loggedIn) {
      await UserStore.getUser();
    }
    if (JWTStore.loggedIn && this.isConsumer()) {
      this.language = this.$i18n.locale;
      if (this.$route.query.page) {
        this.pageNum = Number(this.$route.query.page);
      }

      if (this.$route.query.crop) {
        this.crop = Number(this.$route.query.crop);
        await DemandSupplyStore.getCurrentSubsidyBatch({ cropId: this.cropId }).catch(err => {
          this.$notify.error("Couldn't get Subsidy Batch, please refresh the page! ");
        });
      }

      // CropStore.getDSADCropName({ phase: "demand", lang: this.language });
      // CropVarietyStore.getAllCropVarietiesList();
      DemandSupplyStore.getAllSeedType();

      DemandSupplyStore.getSubsidyCrops({ lang: this.language });
      DemandSupplyStore.getSubsidy({ lang: this.language });
      DemandSupplyStore.getAllSupplies();

      this.loading = true;

      await DemandSupplyStore.getAllDemands({
        lang: this.language,
        crop: this.crop,
        subsidyBatch: this.currentSubsidyBatch.id
      });
      console.log("DL -> ", this.dataList);
      if (this.dataList.length == 0) {
        this.disableConfirm = true;
      }
      this.loading = false;
      (this.$refs.dataTable as any).toggleAllSelection();
    } else {
      eventHub.$emit("loginClick", "companyUser", "You need to login as company user to continue");
    }
  }

  handlePageChange(val: number) {
    this.pageNum = val;
    // CropVarietyStore.setPageNumber(val);
    CropVarietyStore.getPaginatedCropValue({
      search: this.searchInput,
      page: val,
      language: this.language
    });
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: { page: String(val) }
        })
        .catch(error => {});
    }
  }
}
